<template>
	<div class="main-contents display">
		<div class="tit">하이프로 뉴스 관리</div>
		<div class="search-box">
			<InputComp title="하이프로 뉴스명" v-model="input.srchTxt" @keyup.enter="getList()"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList()">
			<div class="sub_btn" @click="$router.push('BBS902M02')">+ 뉴스등록</div>
			<div class="sub_btn dl" @click="delNotice()">삭제</div>
			<div class="sub_btn end" @click="offNotice()">노출종료</div>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="3%">
					<col width="7%">
					<col width="45%">
					<col width="15%">
					<col width="15%">
                    <col width="15%">
				</colgroup>
				<thead>
					<tr>
						<th><SelectComp type="yn" :value="Object.keys(checks).length != 0 && Object.keys(checks).length == list.length ? 'Y':'N'" @change="checkAll()"/></th>
						<th>NO</th>
						<th>제목</th>
						<th>등록일</th>
						<th>노출여부</th>
						<th>상태</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!list || list.length == 0">
						<!-- 관련내용 없을 경우 -->
						<td colspan="6" class="none">등록된 하이프로 뉴스가 없습니다!</td>
					</tr>
					<tr v-else v-for="(news, index) in list" :key="news.newsCenterSeq">
						<td class="score"><SelectComp type="yn" :value="checks[news.newsCenterSeq] ? 'Y':'N'" @change="checkItem(news)"/></td>
						<td class="score">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + index + 1}}</td>
						<td class="score"><div class="new" v-if="news.newNews == 'Y'">new</div>{{news.newsCenterTitle}}</td>
						<td class="score">{{news.newsCenterReg | date('yyyy. MM. dd')}}</td>
						<td class="score" v-if="news.newsCenterExposureYn == 'Y'">예</td>
                        <td class="score" v-else>아니오</td>
						<td class="score">
							<div class="bn_btn" @click="goDetail(news)">수정</div>
							<div class="bn_btn" @click="delNotice(news)">삭제</div>
						</td>
					</tr>
					<!-- 최대 25건 조회 -->
					
				</tbody>
			</table>
		</div>
		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
export default { 
	components: { pagingComp },
    data() {
        return {
            input: {
                srchTxt: '',
				pageIndex: 1,
            },
            list: [],
			checks:{},
			pageInfo : {},
        }
    },
	mounted(){
		this.getList();
	},
	methods: {
		getList() {
			this.$.httpPost('/api/main/bbs/adm/news/getNewsList', this.input)
                .then(res => {
					this.checks = {};
                    this.list = res.data.list;
                    this.pageInfo = res.data.pageInfo;
                }).catch(this.$.httpErrorCommon);
		},

		goDetail(item) {
			this.$router.push({name: 'BBS902M02', params:{newsCenterSeq:item.newsCenterSeq}});
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		checkItem(item){
			// console.log('checkItem start', item, Object.keys(this.checks).length, JSON.stringify(this.checks));
			var newsSeq = item.newsCenterSeq;
			if(this.checks[newsSeq]){
				delete this.checks[newsSeq];
			} else {
				this.checks[newsSeq] = item;
			}
			// console.log(this.checks);
			this.$forceUpdate();
			// console.log('checkItem end', item, Object.keys(this.checks).length, JSON.stringify(this.checks));
		},

		checkAll(){
			// console.log('checkAll start', Object.keys(this.checks).length, JSON.stringify(this.checks));
			var length = Object.keys(this.checks).length;
			if(length != this.list.length){
				for(var i in this.list){
					this.checks[this.list[i].newsCenterSeq] = this.list[i];
				}
			} else {
				this.checks = {};
			}
			this.$forceUpdate();
			//console.log('checkAll end', Object.keys(this.checks).length, JSON.stringify(this.checks));
		},

		offNotice(item) {
			//console.log('offNews start', JSON.stringify(this.checks));
			if(this.list.length == 0){
				return alert('조회 된 하이프로 뉴스가 없습니다.');
			}

			var useYn = 'N', list = [];
			if(item){
				list.push(item);
			} else {
				if(Object.keys(this.checks).length == 0){
					return alert('선택 된 하이프로 뉴스가 없습니다.');
				}
				var listN = [];
				for(var key in this.checks){
					var item1 = this.checks[key];
					if(item1.useYn == 'Y'){
						list.push(item1);
					} else {
						listN.push(item1);
					}
				}
				if(list.length == 0){
					useYn = 'Y';
					list = listN;
				}
			}
			this.$.popup('/adm/bbs/BBS902P01', {useYn, list})
				.then(res => {
					if(res) {
						this.$.httpPost('/api/main/bbs/adm/news/offNews', {list : list, useYn: useYn})
						.then(() => {
							alert("정상적으로 처리되었습니다.");
							this.getList();
						}).catch(this.$.httpErrorCommon);
					}
				});
		},

		delNotice(item) {
			// console.log('delNews start', JSON.stringify(this.checks));
			if(this.list.length == 0){
				return alert('조회 된 하이프로 뉴스가 없습니다.');
			}
			var list = [];
			if(item){
				list.push(item);
			} else {
				if(Object.keys(this.checks).length == 0){
					return alert('선택 된 하이프로 뉴스가 없습니다.');
				}
				for(var key in this.checks){
					list.push(this.checks[key]);
				}
			}
			this.$.popup('/adm/bbs/BBS902P02', {list})
				.then(res => {
					if(res) {
						this.$.httpPost('/api/main/bbs/adm/news/delNews', {list : list})
						.then(() => {
							alert("정상적으로 처리되었습니다.");
							this.getList();
						}).catch(this.$.httpErrorCommon);
					}
				});
		}
	},

	
}
</script>